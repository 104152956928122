<template>
  <Teleport to="body">
    <Transition name="fade" >
      <div class="addpopup" @click="$emit('update:modelValue', false)" v-show="modelValue">
        <div class="addpopup__content" @click.stop>
          <div style="margin-bottom: 5px">Имя папки:</div>
          <input class="addpopup__input" placeholder="Имя" type="text" v-model="newname">
          <div style="margin-bottom: 5px">Фильтр по сайтам:</div>
          <input class="addpopup__input" placeholder="Фильтр" type="text" v-model="filter">
          <div class="addpopup__checks">
            <label v-for="project in allProjectList.filter(project => project.match(filter))" :key="project">
              <input type="checkbox" v-model="projectmap[project]">
              <div>{{project}}</div>
            </label>
          </div>
          <div style="margin-bottom: 5px">Доступы:</div>
          <div class="addpopup__checks">
            <label v-for="user in userlist" :key="user.log">
              <input type="checkbox" v-model="usermap[user._id]">
              <div>{{user.log}}</div>
            </label>
          </div>
          <Btn @click="send">Изменить</Btn>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import Btn from './buttons/Btn.vue'
  export default {
    data: ()=>({
      filter: '',
      newname: '',
      usermap: {},
      projectmap: {}
    }),
    props: {
      modelValue: {type: Boolean, default: false},
    },
    components: { Btn },
    methods: {
      recalcUsermap(){
        let res = {}
        for (let user of this.userlist)
          res[user._id] = this.folder? 
            (this.folder.accesses.includes(user._id))
            : false
        this.usermap = res
      },
      recalcProjectmap(){
        let res = {}
        for (let project of this.allProjectList) 
          res[project] = this.folder?
            (this.folder.projects.includes(project))
            : false
        this.projectmap = res
      },
      async recalcAll(){
        await this.$store.dispatch('getUserList')
        this.recalcUsermap()
        this.recalcProjectmap()
        if (this.folder && this.folder.name) this.newname = this.folder.name
        else this.newname = ''
      },
      async send(){
        this.$store.commit('toggleLoad','foldersettingspopup')
        const name = this.folder.name
        let accesses = []
        
        for (let user in this.usermap) 
          if (this.usermap[user]) 
            accesses.push(user)

        await fetch(this.url + 'folder', {
          method: 'PUT',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: name,
            accesses: accesses,
            newname: this.newname,
            projectmap: this.projectmap
          })
        }).then(async res=>{
          if (!res.ok) throw(await res.text())
          await this.$store.dispatch('updateFoldersData', this.newname)
          await this.recalcAll()
        }).catch(err=>{console.error(err); alert(err)})
        this.$store.commit('toggleLoad','foldersettingspopup')
      }
    },
    computed: {
      ...mapState(['url','folder', 'userlist']),
      ...mapGetters(['allProjectList'])
    },
    watch: {
      modelValue(v) {
        if (v)
          this.recalcAll()
      },
      folder(v){
        if (v && v.name && v.name !== this.newname)
          this.filter = ''
      }
    }
  }
</script>

<style lang="sass">
.addpopup
  position: fixed
  z-index: 11
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  top: 0
  left: 0
  background: rgba(255,255,255,.6)
  &__content
    width: 550px
    max-width: 100%
    background: rgb(230, 230, 255)
    padding: 15px 20px
    border-radius: 20px
    box-shadow: 1px 1px 4px rgba(0,0,0,.3)
    overflow-y: auto
    >*:not(:last-child)
      margin-bottom: 15px
  &__input
    resize: none
    width: 100%
    padding: 5px 10px
    background: white
    border: 1px solid rgba(0,0,0,.15)
  textarea.addpopup__input
    height: 400px
  &__checks
    max-height: 400px
    overflow-y: auto 
    label
      display: flex
      align-items: center
      font-size: 16px
      &:not:last-child
        margin-bottom: 8px
      input
        margin-right: 5px
</style>