import { createStore } from 'vuex'
import { decompress } from 'shrink-string'

export default createStore({
  state: {
    url: '',
    loads: {},
    Role: null,
    userlist: [],
    FolderList: [],
    AllProjectList: [],
    folder: null,
    folderData: null,
    project: null,
    allIndexData: null
  },
  mutations: {
    setAllIndexData(state, value) {
      state.allIndexData = value
    },
    setUrl(state, url) {
      state.url = url
    },
    toggleLoad(state, name) {
      if (state.loads[name])
        delete state.loads[name]
      else
        state.loads[name] = true
    },
    setRole(state, Role) {
      state.Role = Role
    },
    setUserList(state, list) {
      state.userlist = list
    },
    setAllProjectList(state, list) {
      state.AllProjectList = list
    },
    setFolderList(state, list) {
      state.FolderList = list
    },
    setFolder(state, value) {
      state.folder = value
      if (!value)
        state.folderData = null
    },
    setFolderData(state, value) {
      state.folderData = value
    },
    setProject(state, value) {
      state.project = value
    },
  },
  actions: {
    reset({ commit }) {
      commit('setRole', false)
    },
    async setFolderByName({ state, commit }, name) {
      for (let i = 0; i < state.FolderList.length; i++)
        if (state.FolderList[i].name == name) {
          commit('setProject', null)
          return commit('setFolder', state.FolderList[i])
        }
    },
    async checkAuth({ state, commit }) {
      commit('toggleLoad', 'checkAuth')
      await fetch(state.url + 'auth', {
        credentials: 'include',
      })
        .then(res => {
          if (!res.ok) return commit('setRole', false)
          return res.text()
        }).then(role => {
          commit('setRole', role || false)
        }).catch(err => {
          alert(err)
          commit('setRole', false)
        })
      commit('toggleLoad', 'checkAuth')
    },
    async getUserList({ state, commit }) {
      commit('toggleLoad', 'getUserList')
      await fetch(state.url + 'userlist', {
        credentials: 'include',
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => commit('setUserList', list))
        .catch(err => { console.error(err); alert(err) })
      commit('toggleLoad', 'getUserList')

    },
    async getAllProjectList({ state, commit }) {
      commit('toggleLoad', 'getAllProjectList')
      await fetch(state.url + 'projectlist', {
        credentials: 'include',
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => commit('setAllProjectList', list))
        .catch(err => { alert(err); console.log(err) })
      commit('toggleLoad', 'getAllProjectList')
    },
    async getFolderList({ state, commit, dispatch }) {
      commit('toggleLoad', 'getFolderList')
      await fetch(state.url + 'folderlist', {
        credentials: 'include',
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(async list => {
          if (state.Role == 2)
            await dispatch('getAllProjectList')
          list.sort((a, b) => a.name.localeCompare(b.name))
          commit('setFolderList', list)
        })
        .catch(err => { alert(err); console.log(err) })
      commit('toggleLoad', 'getFolderList')
    },
    async getDeltaData({ state, commit }, { project, dates }) {
      const datestart = dates[0]
      const datefinish = dates[1] || dates[0]

      commit('toggleLoad', 'getDeltaData')
      console.log(state.folder.name)
      await fetch(state.url + `deltaData?folder=${state.folder.name}&project=${encodeURIComponent(project)}&datestart=${encodeURIComponent(datestart)}&datefinish=${encodeURIComponent(datefinish)}`, {
        credentials: 'include',
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        return res.json()
      })
        .then(async json => {
          json = json.map(el => ({ ...el, date: new Date(el.date) }))
          json.sort((a, b) => a.date - b.date)
          json = (await Promise.all(json.map(el => decompress(el.delta)))).map(el => JSON.parse(el))
          const added = new Set, removed = new Set
          for (let delta of json) {
            for (let ad of delta.added)
              if (removed.has(ad))
                removed.delete(ad)
              else 
                added.add(ad)
            for (let rm of delta.removed)
              if (added.has(rm))
                added.delete(rm)
              else
                removed.add(rm)
          }

          const filename = `delta_${project}_${datestart}_${datefinish}.txt`
          const text = `Добавлено:\n${[...added].join('\n')}\n\nУдалено:\n${[...removed].join('\n')}`;
          const blob = new Blob([text], { type: 'text/plain' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch(err => {
          console.error(err)
          alert(err)
        })
      commit('toggleLoad', 'getDeltaData')
    },
    async updateFoldersData({ state, commit, dispatch }, newname) {
      await dispatch('getFolderList')
      if (newname || (state.folder && state.FolderList.find(f => f.name == state.folder)))
        dispatch('setFolderByName', newname || state.folder.name)
      else {
        commit('setProject', null)
        commit('setFolder', null)
      }
    },
    async getProject({ state, commit }, url) {
      commit('toggleLoad', 'getProject')
      await fetch(state.url + `project?url=${encodeURIComponent(url)}&folder=${encodeURIComponent(state.folder.name)}`, {
        credentials: 'include',
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(project => commit('setProject', project))
        .catch(err => { console.error(err); alert(err) })
      commit('toggleLoad', 'getProject')
    }
  },
  getters: {
    loading(state) {
      return Object.keys(state.loads).length > 0
    },
    folderdates(state) {
      if (!state.FolderList || !state.folder) return null
      const pcounts = state.folder.pcounts
      let dates = {}
      for (let count of pcounts) {
        for (let date in count)
          dates[date] = true
      }
      dates = Object.keys(dates).map(el => {
        const [dd, mm, yy] = el.split('/').map(el => parseInt(el))
        return [el, dd + mm * 50 + yy * 1000]
      })
      dates.sort((a, b) => a[1] - b[1])
      dates = dates.map(el => el[0])
      return dates
    },
    projectList(state) {
      if (!state.FolderList || !state.folder) return []
      const copy = state.folder.projects.slice()
      copy.sort((a, b) => a.localeCompare(b))
      return copy
    },
    pages(state) {
      if (!state.project) return []
      return state.project.pages[state.project.pagemode + 0]
    },
    allProjectList(state) {
      if (!state.FolderList) return []
      if (state.Role == 2) return state.AllProjectList
      return Array.from(new Set(state.FolderList.map(el => el.projects).flat()))
    }
  }
})
